import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const MobilePersonalCare = () => {
  const [linkOneHover, setLinkOneHover] = useState(false);

  return (
    <div className={`mobile-perfume transition-all ease-in-out duration-300`}>
      <button
        onClick={() => setLinkOneHover(!linkOneHover)}
        className={`mr-10 w-[85%] text-start relative ${
          linkOneHover ? "text-accent" : "text-black"
        } font-bold `}
      >
        PERSONAL CARE
        <FontAwesomeIcon
          className=" w-3 absolute right-0"
          icon={faChevronDown}
        ></FontAwesomeIcon>
      </button>

      {/* Face Hover product-Category  */}

      <div
        className={`mobile-menu-hover ${
          linkOneHover ? "grid-transition text-start px-4 py-4 " : ""
        } text-black `}
      >
        <div>
          <hr className=" w-full my-2 text-accent" />
          <Link
            to="/product-category/personal care"
            className=" hover:text-accent"
          >
            All Products
          </Link>
          <hr className=" w-full my-2 text-accent" />
          <Link to="/product-category/face wash" className=" hover:text-accent">
            Face Wash
          </Link>
          <hr className=" w-full my-2 text-accent" />
          <Link
            to="/product-category/shampoo"
            className=" hover:text-accent"
          >
            Shampoo
          </Link>
          <hr className=" w-full my-2 text-accent" />
          <Link
            to="/product-category/hair color"
            className=" hover:text-accent"
          >
            Hair Color
          </Link>
          <hr className=" w-full my-2" />
          <Link
            to="/product-category/hair & body Wash"
            className=" hover:text-accent"
          >
            Hair & Body Wash
          </Link>
          <hr className=" w-full my-2" />
          <Link
            to="/product-category/fairness cream"
            className=" hover:text-accent"
          >
            Fairness Cream
          </Link>
          <hr className=" w-full my-2" />
          <Link
            to="/product-category/body lotion"
            className=" hover:text-accent"
          >
            Lotion
          </Link>
          <hr className=" w-full my-2" />
          <Link
            to="/product-category/fragrances"
            className=" hover:text-accent"
          >
            Fragrance
          </Link>
          <hr className=" w-full my-2" />
          <Link
            to="/product-category/baby care"
            className=" hover:text-accent"
          >
            Baby Care
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MobilePersonalCare;
